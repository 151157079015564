import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = ({ data }) => {
  const handleChange = (e) => {};

  //const {captchaKey, setCaptchaKey }= useState('');
  const onCaptchaChange = (e) => {
    //console.log('captchaevent', e); 
    document.querySelector('.captchaKey').value = e; 
    //console.log('value set=', document.querySelector('.captchaKey').value);
  };

  if (data) {
    var name = data.name;
    var street = data.address.street;
    var city = data.address.city;
    var state = data.address.state;
    var zip = data.address.zip;
    var phone = data.phone;
    var email = data.email;
    var message = data.contactmessage;
  }

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{message}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form action="" method="post" id="contactForm" name="contactForm">
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={handleChange}
                  minLength="1"
                  maxLength="75"
                />
              </div>

              <div>
                <label htmlFor="contactEmail">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  onChange={handleChange}
                  minLength="1"
                  maxLength="60"
                />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={handleChange}
                  maxLength="50"
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="contactMessage" 
                  maxLength="300"
                  minLength="1"
                ></textarea>
              </div>
              <div style={{display: "flex", alignItems: "center"}}>
                <label htmlFor="captcha">Verification</label><span className="required">*</span>
                <div style={{width:"50%"}}>
                <ReCAPTCHA id="captcha"
                  //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //development key
                  sitekey="6LePLVgaAAAAAGrv7lubXLA41-0iMeDbM-ASQE0B"
                  onChange={onCaptchaChange}
                  />
                  <input className='captchaKey' type='hidden'></input>
                </div>
                
              </div>              
              <div>
                <button className="submit">Submit</button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>

          <div id="message-warning"> Error boy</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Address and Phone</h4>
            <p className="address">
              {name}
              <br />
              {street} <br />
              {city}, {state} {zip}
              <br />
              <span>{phone}</span>
            </p>
          </div>

          <div className="widget widget_tweets">
            
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
